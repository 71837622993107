<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newItem'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add New Item
            </h5>
            <a
              class="close"
              @click="$store.dispatch('modalClose', 'addNewItem')"
              title="Close"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div >
          <form
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mt-0 mb-1">
                            <input
                              type="file"
                              id="Item_image"
                              style="display: none"
                            />
                            <label for="Item_image" class="popup-img-container">
                              <span
                                >Browse <br />
                                Image</span
                              >
                              <div class="popup_img">
                                <img
                                  class="popup_img"
                                  src=""
                                />
                                <div id="change_img">
                                  Change <br />
                                  Image
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="col-md-7 pl-0 pd-t-30 pb-0">
                            <div class="row">
                              <div class="col-md-5 mr-0 pr-0">Item Code</div>
                              <div class="col-md-7 ml-0 pl-0">
                                <input
                                  class="form-control"
                                  placeholder="Item Code"
                                />
                              </div>
                               <!-- <span class="unique-message">Item Code should be unique </span> -->
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Item Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Item Name"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Item Group *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                            >
                              <option value="">Item Group</option>
                            </select>
                            <div class="input-group-prepend">
                              <button
                                type="button"
                                class="btn-primary input-group-add-btn"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                          <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Brand </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                            >
                              <option value="">Select Brand</option>
                            </select>
                            <div class="input-group-prepend">
                              <button
                              
                                type="button"
                                class="btn-primary input-group-add-btn"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              style="height: 2.85rem"
                            ></textarea>
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              style="height: 2.35rem"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Unit Type</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Unit *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control">
                              <option value="">Unit Type</option>
                            </select>
                            <div class="input-group-prepend">
                              <button
                                type="button"
                                class="btn-primary input-group-add-btn"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Stock Setting</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            type="checkbox"
                            id="stock_required"
                            value="1"
                          />
                          <label for="stock_required" class="ml-2"
                            >Stock Required</label
                          >
                        </div>
                        <div class="group-note mb-2">
                          <span>Note: </span> Please Uncheck the option if Stock
                          is not required to item
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Minimum Stock</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              placeholder="Min for Notification"
                              class="form-control pr-4"
                            />
                            <span class="input-inside-icon"
                              ><i class="fas fa-info-circle pd-t-3"></i
                            ></span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">
                            Expiry Days
                          </div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              placeholder="Notification before expity"
                              class="form-control pr-4"
                            />
                            <span class="input-inside-icon"
                              ><i class="fas fa-info-circle pd-t-3"></i
                            ></span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Sales Rate</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input type="number" step="any" class="form-control" placeholder="Rate" />
                            <!-- <input type="number" step="any" class="form-control" placeholder="Rate" v-model="formData.sales_rate" disabled v-else/> -->
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Discount</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <div class="input-group">
                              <input type="number" step="any" class="form-control" placeholder="0.00" />
                              <div class="input-group-prepend">
                                <span class="input-group-text" style="min-width: 0px">%</span>
                              </div>
                              <input type="number" step="any" class="form-control" placeholder="0.00" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              style="height: 6.75rem"
                            ></textarea>
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              style="height: 4.29rem"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Unit Type</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Unit *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control">
                              <option value="">Unit Type</option>
                            </select>
                            <div class="input-group-prepend" >
                              <button
                                
                                type="button"
                                class="btn-primary input-group-add-btn"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">VAT Settings</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            type="checkbox"
                            name="expense_type"
                            id="vatable"
                            value="0"
                          />
                          <label for="vatable" class="ml-2"
                            >VAT Applicable</label
                          >
                        </div>
                        <div class="group-note">
                          <span>Note: </span> Please Check the option if VAT is
                          applicable to item
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="button" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components:{
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
      "authData"
    ]),
    ...mapGetters("items",[
      "itemLists",
      "itemElements",
    ]),
  },
};
</script>
<style>
.unique-message{
  color:#DB4437;
}
</style>
